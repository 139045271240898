import React, { useState, useEffect, useContext } from 'react'
import { Grid, useMediaQuery } from '@chakra-ui/react'
import ProductCard from './product-card'
import { FilterContext } from './filter-context'

const ProductListingOnly = ({
  // products,
  // collection,
  // filter,
  childPositionLast,
  children,
  maximum = 99,
  offset = 1,
  bottomlines = [],
  ...props
}) => {
  // let productList = []
  const [activeProducts, setActiveProducts] = useContext(FilterContext)
  const [isSmallerThan1024] = useMediaQuery('(max-width: 1024px)')
  // const [activeProducts, setActiveProducts] = useState(productList)

  // Combine all products into single array
  // if (collection && collection.nodes[0]) {
  //   productList = productList.concat(collection.nodes[0].products)
  // }

  // if (products) {
  //   products.forEach((product) => {
  //     if (product.variants[0].availableForSale) productList.push(product)
  //   })
  // }

  const [isMaxWidthMedium, setIsMaxWidthMedium] = useState(false)

  useEffect(() => {
    if (isSmallerThan1024 !== isMaxWidthMedium) {
      setIsMaxWidthMedium(isSmallerThan1024)
    }
  }, [isSmallerThan1024, isMaxWidthMedium])

  function getBottomLine(id) {
    if (typeof id != 'undefined') {
      const gid = id.substring('gid://shopify/Product/'.length)
      const bottomLine =
        bottomlines.length > 0
          ? bottomlines.find((o) => o.productIdentifier === gid)
          : null
      // bottomLine != null && console.log('bottomLineFound: ' + bottomLine.score)
      return bottomLine
    }
  }

  return (
    <Grid
      {...props}
      templateColumns={[
        '1fr',
        'repeat(2, 1fr)',
        'repeat(3, 1fr)',
        'repeat(4, 1fr)',
      ]}
      columnGap={[2, 3, 4, 4]}
      rowGap={[0]}
    >
      {childPositionLast && children}
      {activeProducts.length > 0 &&
        activeProducts.slice(offset - 1, maximum + offset - 1).map(
          (p, index) =>
            p != null && (
              <>
                <ProductCard
                  product={p}
                  key={`${p.title} + active + ${index}`}
                  bottomline={getBottomLine(p.shopifyId)}
                />
                {!childPositionLast &&
                  index === (isMaxWidthMedium ? 3 : 2) &&
                  children}
              </>
            )
        )}
    </Grid>
  )
}

export default ProductListingOnly
