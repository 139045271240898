import React from 'react'
import Filter from './filter'

export const ProductListingFilter = ({
  products,
  collection,
  children,
  inclusionList = [],
  ...props
}) => {
  let productList = []

  // Combine all products into single array
  if (collection && collection.nodes[0]) {
    productList = productList.concat(collection.nodes[0].products)
  }

  if (products) {
    products.forEach((product) => {
      if (product.variants[0].availableForSale) productList.push(product)
    })
  }

  return (
    <Filter productList={productList} inclusionList={inclusionList} {...props}>
      {children}
    </Filter>
  )
}

export default ProductListingFilter
