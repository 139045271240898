import * as React from 'react'

import { Container, Heading } from '@chakra-ui/react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { FilterProvider } from '../../components/filter-context'
import ProductListingFilter from '../../components/product-listing-filter'
import ProductListingOnly from '../../components/product-listing-only'

const Collection = ({
  data: { allProductsCollection },
  pageContext: { breadcrumb },
}) => {
  return (
    <Layout breadcrumbs={breadcrumb}>
      <Seo
        title={`Pie Shop | Award Winning Pies | Mud Foods`}
        description={`Browse our full range of hand-made products, from award-winnings pies, to side dishes & gourmet meals. Shop today at Mud Foods and get your taste buds tingling!`}
      />

      <Container py={5}>
        <Heading as="h1" textStyle="h1">
          {allProductsCollection.title}
        </Heading>
        <FilterProvider>
          <ProductListingFilter
            products={allProductsCollection.products}
            textAlign={'center'}
          />
          <ProductListingOnly>
            {/* <DummyProduct
              title={'Create Your Own Gift Box'}
              image={allProductsCollection.image}
              link={'/products/create-a-gift-box'}
            /> */}
          </ProductListingOnly>
        </FilterProvider>
      </Container>
    </Layout>
  )
}

export default Collection

export const query = graphql`
  {
    allProductsCollection: shopifyCollection(
      handle: { eq: "all-products-pies-by-post" }
    ) {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(aspectRatio: 1, formats: [AUTO, WEBP])
          }
        }
      }
      seo {
        description
        title
      }
      title
      products {
        ...ProductCard
      }
    }
  }
`
